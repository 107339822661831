.wrapper {
    display: flex;
    align-items: center;
    padding: 8px 0;
    margin: 8px 0;
    cursor: pointer;
}

.wrapper:hover {
    background-color: rgb(229,231,235);
    color: var(--gray-text);
    border-radius: 0.5rem;
}

.text {
    font-size: 14px;
    min-width: 230px;
    width: 230px;
}

.arrow {
    width: 1rem;
    height: 1rem;
    margin-left: 0.25rem;
}

.open {
    transform: rotateX(180deg); 
}

.close {
    transform: rotateX(360deg); 
}

.icon {
    font-size: 1.7rem;
    width: 40px;
    margin-right: 12px;
    min-width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--gray-text);
}

.homeIcon {
    width: 28px;
    height: 28px;
    margin-right: 18px;
    min-width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    fill: var(--gray-text);
    margin-left: 6px;
}

