@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-display: swap;
  src: url('./fonts/Nunito.woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

button.btn.btn-secondary, button.btn.btn-danger, button.btn.btn-primary, button.btn.btn-success {
  font-family: 'Nunito', ui-sans-serif,system-ui;
}

.btn-dark, .btn-danger, .btn-success,  .btn-primary {
  padding: 8px 16px;
  font-size: 12px !important;
  line-height: 1rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  background-color: var(--dark-gray);
}

input.form-control:disabled {
  background-color: #e9ecef82;
}

:root {
  --tw-text-opacity: 1;
  --text-gray-900: rgb(17 24 39 / var(--tw-text-opacity));
  --dark-gray: rgb(31 41 55 / var(--tw-bg-opacity));
  --tw-shadow:  0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored:  0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  --tw-bg-opacity: 1;
  --gray-100: rgb(243 244 246/var(--tw-bg-opacity));
  --gray-text: #374151;
  --gray-500: rgb(107 114 128/var(--tw-text-opacity)) ;
  }

  html, body, head, #root{
    height: 100%;
    font-family: 'Nunito', ui-sans-serif,system-ui;
    box-sizing: border-box;
    color: var(--gray-text);
  }

 html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%; 
  }
  
  body {
    margin: 0;
    padding: 0;
    margin: 0;
    font-family: 'Nunito', ui-sans-serif,system-ui;
  }
  
  main {
    display: block;
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  hr {
    box-sizing: content-box; /* 1 */
    height: 0; /* 1 */
    overflow: visible; /* 2 */
  }
  
  pre {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
  }
  
  a {
    background-color: transparent;
  }

  abbr[title] {
    border-bottom: none; /* 1 */
    text-decoration: underline; /* 2 */
    text-decoration: underline dotted; /* 2 */
  }
  
  b,
  strong {
    font-weight: bolder;
  }
  
  code,
  kbd,
  samp {
    font-family: monospace, monospace; 
    font-size: 1em; 
  }

  
  small {
    font-size: 80%;
  }
  
  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }
  
  sub {
    bottom: -0.25em;
  }
  
  sup {
    top: -0.5em;
  }
  
  img {
    border-style: none;
  }
  
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; 
    font-size: 100%; 
    line-height: 1.15; 
    margin: 0; 
  }
  
  button,
  input {
    overflow: visible;
  }
  
  
  button,
  select {
    text-transform: none;
  }
  
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }
  
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
  fieldset {
    padding: 0.35em 0.75em 0.625em;
  }
  
  legend {
    box-sizing: border-box; /* 1 */
    color: inherit; /* 2 */
    display: table; /* 1 */
    max-width: 100%; /* 1 */
    padding: 0; /* 3 */
    white-space: normal; /* 1 */
  }
  
  progress {
    vertical-align: baseline;
  }
  
  textarea {
    overflow: auto;
  }

  [type='checkbox'],
  [type='radio'] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
  }
  
  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  
  [type='search'] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
  }
  
  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
  }
  
  details {
    display: block;
  }
  
  summary {
    display: list-item;
  }
  
  template {
    display: none;
  }
  
  [hidden] {
    display: none;
  }
  
  .unselectable {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  
div.leaflet-bottom.leaflet-right{
  display: none;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
  display: none;
}

.ant-table-thead tr th.ant-table-cell {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 500;
  font-size: 0.75rem;
  color: var(--gray-500);
}

tbody tr td.ant-table-cell{
  font-size: 0.875rem;
  color: var(--gray-500);
  line-height: 1.25rem;
  font-weight: 300;
  padding: 16px 10px;
}

.ant-table-content{
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15)!important;
}

div.ant-slider-rail{
  background-color: #91d5ff5c;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

button#dropdown-basic.dropdown-toggle.btn.btn-light{
  background-color: white;
  border-radius: 0.25rem;
  border: 1px solid #dee2e6;
  color: #212529;
}

li.ant-pagination-options{
  display: none;
}

div.ant-tooltip.ant-slider-tooltip.ant-tooltip-placement-top{
  z-index:100;
}