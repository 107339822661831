.wrapper {
  display: flex;  
  flex-direction: column;
  width: 50%;
}

.text{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.timesWrapper {
  display: flex;
}

.label{
  cursor: pointer;
  margin-left: 5px;
  color: var(--gray-text);
}
