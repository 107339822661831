.slider {
  margin-top: 50px;
  width: 100%;

}

.subtitle {
  margin-top: 10px;
}
 
.form {
  box-sizing: border-box;
  margin-left: 10px;
  width: 55px;
}

.sliderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px;
}

.input {
  font-size: 14px;
}