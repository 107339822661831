.header {
    width: 100%;
    height: 90px;
    border-bottom: 1px solid rgb(229 231 235);
    display: flex;
    align-items: center;
    padding: 24px;
    justify-content: space-between;
    margin-left: 60px;
}

.mainTitle{
    display: none;
    font-size: 0.95rem;
    line-height: 1.75rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 600;
    color: var(--gray-text);
}

.svg {
    display: none;
    width: 1.5rem;
    cursor: pointer;
}

@media screen and (max-width: 1024px) {
    .authMenu {
        display: none;
    }
    .svg {
        display: block;
        cursor: pointer;
    }
    .mainTitle {
        display: block;
    }
}

