.App {
  height: 100%;
  background-color: var(--gray-100);
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;
}

.title{
  display: flex;
  text-align: center;
  color: var(--text-gray-900);
  font-size: 16px;
  padding: 10px 40px;
  margin: 0px 30px 4px 30px;
  max-width: 450px
}