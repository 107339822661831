.menu {
    position: absolute;
    top: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 10000000;
}

.title{
    font-size: 1.125rem;
    line-height: 1.75rem;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-weight: 600;
    padding: 24px;
    text-decoration: none;
    color: var(--gray-text);
}

.title:hover{
    color: var(--gray-text);
}

.menuBlock{
    padding: 0;
    position: relative;
}

.nestedItem{
    margin-right: 10px;
    padding-left: 10px;
}

.nestedItem:hover{
    background-color: rgb(229,231,235);
    color: var(--gray-text);
    border-radius: 0.5rem;
}

.visible{
    display: block;
}

.hide{
    display: none;
}

.link{
  color: var(--gray-text);
    text-decoration: none;
}

.link:hover{
    color: var(--gray-text);
}

.nestedBlock{
    padding-left: 20px;
}