.mainWrapper {
  width: 100%;
  min-height: calc(100vh - 90px);
  background-color: rgb(243,244,246);
  padding: 24px;
  display: flex;
  margin-left: 60px;
}

.table {
  margin-bottom: 15px;
}

.map, .spinMap{
  width: 100%;
}

.secondWrapper{
  margin-left: 24px;
}

.title {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 10px;
  color: var(--gray-text);
}

.tableShowButton {
  font-size: 12px;
}

.firstWrapper{
  width: 60%;
}
.secondWrapper {
  width: 40%
}

@media screen and (max-width: 1350px) {
  .firstWrapper{
    width: 60%;
  }
  .secondWrapper {
    width: 40%;
  }
  .spinMap{
    min-height: auto;
  }
} 

@media screen and (max-width: 1190px) {
  .mainWrapper{
   flex-wrap: wrap;
  }
  .firstWrapper{
    width: 100%;
  }
  .secondWrapper {
    margin-top: 30px;
    width: 100%;
    margin-left: 0;
    display: flex;
  }
  .spinMap{
    width: 50%;
    min-height: auto;
  }
  .modelingWrapper{
    width: 50%;
    margin-left: 30px;
  }
} 