.mainWrapper {
  width: 100%;
  min-height: calc(100vh - 90px);
  background-color: rgb(243,244,246);
  padding: 20px;
  margin-left: 60px;
}

.map {
  width: 100%;
  height: 70vh;
  margin-top: 40px;
}
