.saveButton, .modelButton {
  margin-top: 15px;
  margin-left: 7px;
}

.resetButton {
  margin-top: 15px;
  margin-left: 7px;
}

.tableShowButton {
  margin-top: 15px;
  font-size: 12px;
}

.buttonWrapper {
  display: flex;
  flex-wrap: wrap;
}


