.mainWrapper {
  width: 100%;
  min-height: calc(100vh - 90px);
  background-color: rgb(243,244,246);
  padding: 24px;
  margin-left: 60px;
}

.title {
  font-size: 1.125rem;
  line-height: 1.75rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 600;
  color: var(--gray-text);
  margin-bottom: 30px;
}

.eventCellText {
  border: 1px solid transparent;
  transition: 0.5s;
  padding-bottom: 5px;
}

.eventCell {
  cursor: pointer;
}

.eventCellText:hover {
  font-weight: 700;
  border-bottom: 1px solid #a1a2a5ab;
}

.txtArea{
  display: inline-table;
  width: 100%;
  height: 100%;
  resize: none;
  border: none;
  outline: none;
  cursor: auto;
}