.title {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 10px;
    color: var(--gray-text);
}

.text {
  color: var(--gray-500);
  cursor: pointer;
}


.timesWrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 10px;
  align-items: center;
}

.timesRadioWrapper {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  padding: 6px 14px 16px 14px;
  box-sizing: border-box;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);
}

.trafficIntensityWrapper {
  margin: 20px 0;
}

.radioButton{
  cursor: pointer;
}