.title{
    text-align: center;
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 10px;
    color: var(--gray-text);
  }

.form{
  width: 45px;
}

.input{
  font-size: 14px;
}

.checkbox{
  margin-top: 8px;
  margin-right: 10px;
}

.countingProgramWrapper{
  display: flex;
}

.wrapper{
  height: 246px;
  background-color: white;
  padding: 10px 16px;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);
}