.map-hint-control {
    position: absolute;
    z-index: 99;
    top: 10px;
    right: 7px;
}
.map-hint-btn-wrapper {
    display: flex;
    justify-content: flex-end;
}
.map-hint {
    border-radius: 4px;
    padding: 2px 4px;
    background: #999999;
    opacity: 0.9;
    font-size: 11px;
    color: #ffffff;
    margin-top: 5px;
}
.map-hint-btn {
    border: 1px solid #ccc;
}