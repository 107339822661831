.menu {
    height: 100%;
    width: 60px;
    overflow: hidden;
    transition: .3s;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid rgb(229, 231, 235);
    position: fixed;
    background: #fff;
    z-index: 999;
}

.menu:hover {
    width: 333px;
    overflow: visible;
}

.title {
    font-size: 1.05rem;
    font-weight: 600;
    padding-top: 1rem;
    text-decoration: none;
    color: var(--gray-text);
    text-align: center;
    text-transform: uppercase;
    height: 60px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.title__full {
    min-width: 320px;
    font-size: .9rem;
}

.title:hover {
    color: var(--gray-text);
}

.menuBlock {
    padding: 0 0.5rem;
    position: relative;
}

.nestedBlock {
    min-width: 290px;
    padding: 0 10px 5px;
}

.nestedItem:hover {
    background-color: rgb(229,231,235);
    color: var(--gray-text);
    border-radius: 0.5rem;
}

.visible {
    display: block;
}

.hide {
    display: none;
}

.link {
  color: var(--gray-text);
    text-decoration: none;
}

.link:hover {
    color: var(--gray-text);
}

@media screen and (max-width: 1024px) {
    .menu{
        display: none;
    }
}