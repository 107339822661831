.titleWrapper {
    display: flex;
    flex-wrap: wrap;
}

.title {
    font-size: 0.95rem;
    line-height: 1.75rem;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: 600;
    color: var(--gray-text);
}

.title:hover {
    color: var(--gray-text);
}

@media screen and (max-width: 1124px) {
    .title{
        font-size: 0.85rem;
    }
}

@media screen and (max-width: 1024px) {
    .titleWrapper {
        display: none;
    }
}


