.mainWrapper {
  width: 100%;
  min-height: calc(100vh - 90px);
  background-color: rgb(243,244,246);
  padding: 20px;
  margin-left: 60px;
}

.wrapper {
  display: flex;
  margin-bottom: 20px;
}

.map {
  width: 100%;
  height: 70vh;
}

.buttonWrapper{
  margin-top: 25px;
}

.map{
  height: 70vh;
}

.text{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}