.App {
  height: 100%;
  background-color: white;
  display: flex;
}

.wrapper {
  width: calc(100% - 60px);
}

@media screen and (max-width: 1024px){
  .wrapper {
    width: 100%;
  }
}